import React, {useEffect, useState} from "react";
import {
    Button, Checkbox,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber, List, Modal,
    notification,
    Result,
    Row,
    Select,
    Steps,
    Switch,
    Table
} from 'antd';
import {useTranslation} from 'react-i18next';
import TextArea from "antd/lib/input/TextArea";
import UserService from "./userService";
import moment from "moment";
import 'moment/locale/nl';
import locale from 'antd/es/date-picker/locale/nl_NL';
import {FileDoneOutlined, MedicineBoxOutlined, UserOutlined} from "@ant-design/icons";

const { Option } = Select;
const { Step } = Steps;

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
        message: title,
        description: description,
        placement: "bottomLeft",
        duration: 5,
        bottom: 50
    });
};

const clientLookupByBSN = (bsn, uvvId) => {
    let url = "/v1/client?bsn=" + bsn;

    if (uvvId) {
        url = "/v1/client?bsn=" + bsn + "&uvvId=" + uvvId
    }

    return UserService.authFetch(url, {
        method: "get"
    });
};

const getMedication = () => {
    let url = "/v1/medicatie";

    return UserService.authFetch(url, {
        method: "get"
    });
};

const CareRequestForm = (props) => {
    const { t, i18n } = useTranslation();

    const [currentStep, setCurrentStep] = useState(0);
    const [requestCompleted, setRequestCompleted] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [newPatientId, setNewPatientId] = useState(0);
    const [deliveryMethod, setDeliveryMethod] = useState('levering');
    const [medication, setMedication] = useState({items: []});
    const [appointmentDateUnknown, setAppointmentDateUnknown] = useState(false);
    const [infuus, setInfuus] = useState(false);
    const [bloedAfname, setBloedAfname] = useState(false);
    const [hasExtraMedication, setHasExtraMedication] = useState(false);
    const [lookupClientData, setLookupClientData] = useState(null);
    const [reuseClientDataMode, setReuseClientDataMode] = useState(null);
    const [reuseClientDataUvvId, setReuseClientDataUvvId] = useState(null);
    const [preferentProcedureDate, setPreferentProcedureDate] = useState(null);
    const [forcedUrgent, setForcedUrgent] = useState(false);
    const [pickedMedication, setPickedMedication] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [companyId, setCompanyId] = useState(null);

    const [form] = Form.useForm();

    useEffect( () => {
        async function fetchData() {
            if (props.renewRecord) {
                await handleChangeBsn(props.renewRecord.bsn, props.renewRecord.key, false);
            }
            if (props.editRecord) {
                await handleChangeBsn(props.editRecord.bsn, props.editRecord.key, false);
                await setCurrentStep(0);
            }

            const fetch = await getMedication();
            const medication = await fetch.json();
            await setMedication(medication);

            const token = await UserService.getDecodedToken();
            setInfuus(token && token.company_id && token.company_id === 8857);
            setBloedAfname(token && token.company_id && token.company_id === 13881);
            if (token && token.company_id) {
                setCompanyId(token && token.company_id);
            }
        }

        fetchData();
    }, [props.editRecord, props.renewRecord]);

    useEffect(() => {
        if (bloedAfname) {
            form.setFieldsValue({
                diagnosis: "Overig",
                medication: "N.v.t.",
                num_appointments: "1",
                delivery_method: "verpleegkundige",
                versturen_apotheek: "Niet versturen",
                frequency_times: "1",
                frequency_period: "Eenmalig",
            });
        }
    }, [bloedAfname]);

    useEffect(() => {
        const data = lookupClientData;

        // if (data && data.uvvs && data.uvvs.length && !reuseClientDataMode) {
        //     return
        // }

        if (data && data.id) {
            if (data.delivery_date) {
                data.delivery_date = moment(data.delivery_date);
            }

            if (data.appointment_date_sql) {
                data.appointment_date = moment(data.appointment_date_sql);
            }

            if (data.end_date_sql) {
                data.end_date = moment(data.end_date_sql);
            }

            const formData = {
                bsn: data.bsn,
                dob: data.dob,
                gender: data.gender,
                name: data.name,
                initials: data.initials,
                own_reference: data.eigen_referentie,
                zipcode: data.zipcode,
                housenumber: data.housenumber,
                address: data.address,
                city: data.city,
                phone_mobile: data.phone_mobile,
                phone_home: data.phone_home,
                email: data.email,
                insurance: data.insurance,
                insurance_policy: data.insurance_policy,
                weight: data.weight,
                extra_info: data.extra_info,
                diagnosis: data.diagnosis,
                medication: data.medication,
                prescriber: data.prescriber,
                prescriber_telephone: data.prescriber_telephone,
                prescriber_email: data.prescriber_email,
                agbcode: data.agbcode,
                method: data.method ? capitalizeFirstLetter(data.method) : "Injectie",
                duration: data.duration,
                frequency_times: data.frequency_times,
                frequency_period: data.frequency_period,
                frequency_amount: data.frequency_amount,
                frequency_unit: data.frequency_unit,
                frequency_info: data.frequency_info,
                num_appointments: data.num_appointments,
                urgent: data.urgent || false,
                delivery_method: data.delivery_method,
                delivery_date: data.delivery_date || undefined,
                versturen_apotheek: data.versturen_apotheek,
                appointment_date: data.appointment_date || undefined,
                appointment_date_unknown: data.appointment_date_unknown || false,
                end_date: data.end_date || undefined,
                selfcare_date: data.selfcare_date,
                vigori_meting_gewenst: data.vigori_meting_gewenst,
                port_a_cath: data.port_a_cath,
                noodmedicatie: data.noodmedicatie,
                noodmedicatie_aanwezig: data.noodmedicatie_aanwezig,
                soort_noodmedicatie: data.soort_noodmedicatie,
                dosering_noodmedicatie: data.dosering_noodmedicatie,
                epipen: data.epipen,
                dosering_epipen: data.dosering_epipen,
                uvv_id: data.uvv_id || 0,
                re_id: data.re_id || 0,
            };

            form.setFieldsValue(formData);

            if (bloedAfname) {
                form.setFieldsValue({
                    diagnosis: "Overig",
                    medication: "N.v.t.",
                    num_appointments: "1",
                    delivery_method: "verpleegkundige",
                    versturen_apotheek: "Niet versturen",
                    frequency_times: "1",
                    frequency_period: "Eenmalig",
                });
            }

            if (!props.renewRecord && !props.editRecord) {
                openNotificationWithIcon('success', 'Patient gevonden', 'Er is een patient gevonden voor het ingevoerde BSN. De patient-informatie is automatisch ingevoerd.')
            }
        }
    }, [lookupClientData])

    useEffect(() => {
        const preferent = preferentProcedureDate;
        if (!preferent) {
            return
        }

        const today = moment();
        const tomorrow = moment().add(1, 'day');

        setForcedUrgent(preferent.isSame(today, 'day') || preferent.isSame(tomorrow, 'day'))
    }, [preferentProcedureDate])

    const handleChangeBsn = async (bsn, uvvId, notify) => {
        if (bsn.length !== 9 && bsn.length !== 8 && !(uvvId > 0)) {
            return;
        }

        const request = await clientLookupByBSN(bsn, uvvId);
        const response = await request.json();

        setLookupClientData(response);
    };

    const handleSubmit = async () => {
        await setSubmitting(true)
        form.validateFields()
            .then(async (values) => {
                values.appointment_date = moment(values.appointment_date).toISOString(true);
                values.delivery_date = moment(values.delivery_date).toISOString(true);
                values.end_date = moment(values.end_date).toISOString(true);
                values.selfcare_date = moment(values.selfcare_date).toISOString(true);
                values.urgent = forcedUrgent;

                const request = await UserService.authFetch("/v1/uitvoeringsverzoeken_new", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(values)
                });
                const response = await request.json();
                setNewPatientId(response.patient_id);
                setRequestCompleted(true);
            })
            .catch(errorInfo => {
                console.log(errorInfo)
                return openNotificationWithIcon('error', 'Fouten in formulier', 'Het formulier bevat fouten. De onjuiste invoervelden zijn met een rode tekst gemarkeerd.');
            })
            .finally(() => {
                setSubmitting(false)
            })
    };

    let title = t('newRequest');
    title = (props.editRecord ? "Uitvoeringsverzoek aanpassen" : title);
    title = (props.renewRecord ? "Herhaalrecept aanmaken" : title);

    return (
        <Drawer placement={"top"}
            title={title}
            height={"100vh"}
            style={{minHeight: "700px"}}
            onClose={props.onClose}
            visible={true}
            maskClosable={false}
            bodyStyle={{ paddingBottom: 80 }}
        >
            <Modal
                visible={false && lookupClientData && lookupClientData.id && lookupClientData.uvvs && lookupClientData.uvvs.length > 0 && !reuseClientDataMode}
                title="Gevonden uitvoeringsverzoeken"
                onCancel={() => {
                    form.resetFields()
                    setLookupClientData(null)
                }}
                onClose={() => {
                    form.resetFields()
                    setLookupClientData(null)
                }}>
                <h5>Kies een actie</h5>
                <List>
                    <List.Item key="new">
                        <List.Item.Meta
                            title="Een nieuw uitvoeringsverzoek maken"
                            description="Kies dit voor een nieuwe"
                        />
                    </List.Item>
                    {lookupClientData?.uvvs?.map((uvv) => {
                        return (
                            <List.Item key={`update_${uvv.uvv_id}`}>
                                <List.Item.Meta
                                    title={`Aanmelddatum ${uvv.uvv_aanmelddatum}`}
                                    description={`${uvv.uvv_middel}; ${uvv.uvv_frequentie}`}
                                />
                            </List.Item>
                        );
                    })}
                </List>
            </Modal>

            <Steps current={currentStep} type={"navigation"} onChange={step => {
                if (step === 2) {
                    form.validateFields()
                        .then((values) => {
                            setFormValues(values);
                        });
                }
                setCurrentStep(step);
            }}>
                <Step title={t('patient')} icon={<UserOutlined />} />
                <Step title={t('medical')} icon={<MedicineBoxOutlined />} />
                <Step title={t('checkAndSubmit')} icon={<FileDoneOutlined />} />
            </Steps>

            <Divider type={"horizontal"} />

            <Form layout="vertical" form={form}>
                <Form.Item name="re_id" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="uvv_id" hidden>
                    <Input type="hidden" />
                </Form.Item>

                <div style={{display: currentStep === 0 ? "block" : "none"}}>
                    <h3>{t('name')}</h3>
                    <Row gutter={16}>
                        <Col sm={24} md={8} lg={6}>
                            <Form.Item label={t('BSN')} name="bsn" rules={[{ required: true, message: 'BSN is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} onChange={(e) => handleChangeBsn(e.target.value, undefined, true)} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={8} lg={6}>
                            <Form.Item label={t('dateOfBirth')} name="dob" rules={[
                                {required: true, pattern: new RegExp("^([0-2][0-9]|(3)[0-1])(\\-)(((0)[0-9])|((1)[0-2]))(\\-)\\d{4}$"), message: 'De geboortedatum moet in formaat DD-MM-JJJJ worden ingegeven'},
                            ]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col sm={24} md={8} lg={6}>
                            <Form.Item label={t('gender')} name="gender" rules={[{ required: true, message: 'Geslacht' }]}>
                                <Select size={"large"} placeholder={t('pickAnOption')}>
                                    <Select.Option value={"3"}>Onbekend</Select.Option>
                                    <Select.Option value={"1"}>Man</Select.Option>
                                    <Select.Option value={"2"}>Vrouw</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={16} sm={16} md={16} lg={6}>
                            <Form.Item label="Achternaam" name="name" rules={[{ required: true, message: 'Achternaam is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={6}>
                            <Form.Item label={t('initials')} name="initials" rules={[{ required: true, message: 'Voorletters is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={6} rules={[{required: infuus}]}>
                            <Form.Item label={t('ownReference')} extra={t('ownReferenceDesc')} name="own_reference">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Adres</h3>
                    <Row gutter={5}>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('zipCode')} name="zipcode" rules={[{ required: true, pattern: new RegExp("^[1-9][0-9]{3} ?[A-Za-z]{2}$"), message: 'De postcode moet in het formaat 1234AB worden ingegeven' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('houseNumber')} name="housenumber" rules={[{ required: true, message: 'Huisnummer is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('street')} name="address" rules={[{ required: true, message: 'Straatnaam is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6}>
                            <Form.Item label={t('city')} name="city" rules={[{ required: true, message: 'Woonplaats is een verplicht veld' }]}>
                                <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Contact</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('mobilePhone')} name="phone_mobile" rules={[{ required: true, message: 'Telefoon mobiel is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('homePhone')} name="phone_home" rules={[{ required: false, message: '' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('email')} name="email" rules={[{ required: false, message: '' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Contact in geval van nood (acute bijwerking)</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Naam" name="emergency_contact_name">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Functie" name="emergency_contact_function">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Telefoon" name="emergency_contact_telephone">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3>Verzekering</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={"Verzekeraar"} name="insurance">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={"Polisnummer"} name="insurance_policy">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    {infuus && <>
                        <h3>Overig</h3>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label={"Gewicht"} name="weight">
                                    <InputNumber size={"large"} placeholder="" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    <h3>{t('annotations')}</h3>
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <Form.Item label={t('nonMedicalAnnotations')} extra={t('nonMedicalAnnotationsDesc')} name="extra_info">
                                <TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>

                <div style={{display: currentStep === 1 ? "block" : "none"}}>
                    <>
                        <h3>Diagnose</h3>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label={t('diagnosis')} name="diagnosis" rules={[{ required: true, message: 'Diagnose/indicatie is een verplicht veld' }]}>
                                    <Input disabled={props.editRecord !== undefined} size={"large"} placeholder="" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label={t('medication')} name="medication" rules={[{ required: true, message: 'Medicatie is een verplicht veld' }]}>
                                    <Select
                                      disabled={props.editRecord !== undefined}
                                      size={"large"}
                                      placeholder={t('pickAnOption')}
                                      onChange={(medicationName) => setPickedMedication(medicationName)}
                                    >
                                        <Select.OptGroup label={"Beschikbare medicatie"}>
                                            {medication.items
                                                .filter(m => {
                                                    if (props.editRecord === undefined && m.visible) {
                                                        return true;
                                                    }
                                                    if (props.editRecord !== undefined) {
                                                        const med = props.editRecord.medication.toLowerCase() || "";
                                                        const med_split = med.split(" ");
                                                        const med_name = med_split[0];

                                                        if (m.name.toLowerCase().indexOf(med_name) > -1) {
                                                            return true;
                                                        }
                                                        else {
                                                            return false;
                                                        }
                                                    }
                                                    return true;
                                                }).sort((a, b) => {
                                                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                        return -1;
                                                    }
                                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((m, index) => {
                                                    return (
                                                        <Select.Option key={index} value={m.name} disabled={!m.selectable}>{m.name}</Select.Option>
                                                    )
                                            })}
                                        </Select.OptGroup>
                                        <Select.OptGroup label={"Overig"}>
                                            {medication.items.filter(m => m.visible && !m.selectable).sort((a, b) => {
                                                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                    return -1;
                                                }
                                                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                    return 1;
                                                }
                                                return 0;
                                            }).map((m, index) => {
                                                return (
                                                    <Select.Option key={index + 1000} value={m.name} disabled={!m.selectable}>{m.name}</Select.Option>
                                                )
                                            })}
                                        </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={23} md={8} lg={6}>
                                <Form.Item label="Extra medicatie?" name="has_extra_medication" valuePropName="checked">
                                    <Checkbox disabled={props.editRecord !== undefined} onChange={(e) => setHasExtraMedication((e.target.checked))} />
                                </Form.Item>
                            </Col>
                            {hasExtraMedication && (
                                <Col xs={24} sm={23} md={8} lg={6}>
                                    <Form.Item label="Extra medicatie" name="medication_2" rules={[{ required: hasExtraMedication, message: 'Extra medicatie is een verplicht veld indien de optie "Extra medicatie" is aangevinkt.' }]}>
                                        <Select disabled={props.editRecord !== undefined} disabled={props.editRecord !== undefined} size={"large"} placeholder={t('pickAnOption')}>
                                            <Select.OptGroup label={"Beschikbare medicatie"}>
                                                {medication.items.filter(m => m.visible && m.selectable).sort((a, b) => {
                                                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                        return -1;
                                                    }
                                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((m, index) => {
                                                    return (
                                                        <Select.Option key={index} value={m.name} disabled={!m.selectable}>{m.name}</Select.Option>
                                                    )
                                                })}
                                            </Select.OptGroup>
                                            <Select.OptGroup label={"Overig"}>
                                                {medication.items.filter(m => m.visible && !m.selectable).sort((a, b) => {
                                                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                                                        return -1;
                                                    }
                                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                                        return 1;
                                                    }
                                                    return 0;
                                                }).map((m, index) => {
                                                    return (
                                                        <Select.Option key={index + 1000} value={m.name} disabled={!m.selectable}>{m.name}</Select.Option>
                                                    )
                                                })}
                                            </Select.OptGroup>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                    </>
                    <h3>Voorschrijver</h3>
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label={t('prescriber')} name="prescriber" rules={[{ required: true, message: 'Voorschrijver is een verplicht veld' }]}>
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Telefoon voorschrijver" name="prescriber_telephone">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="E-mailadres voorschrijver" name="prescriber_email">
                                <Input size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="AGB Code" name="agbcode">
                                <InputNumber style={{width: 200}} size={"large"} placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <>
                        <h3>Apotheek</h3>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Naam apotheek" name="pharmacy_name">
                                    <Input size={"large"} placeholder="" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label="Plaats apotheek" name="pharmacy_city">
                                    <Input size={"large"} placeholder="" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>

                    {bloedAfname && (
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <span>Afnamebuizen</span>
                                <Table
                                    pagination={false}
                                    size="small"
                                    style={{marginBottom: 20, marginTop: 10}}
                                    columns={[
                                        {
                                            key: "color",
                                            title: "",
                                            render: (x, row) => {
                                                return (
                                                    <div style={{width: 20, height: 20, backgroundColor: row.colorValue, border: "1px solid #ccc"}}>&nbsp;</div>
                                                )
                                            }
                                        },
                                        {
                                            key: "color",
                                            title: "Kleur buis",
                                            render: (x, row) => {
                                                return (
                                                    <span>{row.color}</span>
                                                )
                                            }
                                        },
                                        {
                                            key: "count",
                                            title: "Aantal",
                                            width: 150,
                                            render: (x, row) => {
                                                return (
                                                    <Form.Item name={row.field}>
                                                        <Input type="number" min={0} defaultValue="0" />
                                                    </Form.Item>
                                                )
                                            }
                                        },
                                    ]}
                                    dataSource={[
                                        {
                                            colorValue: "#fff",
                                            color: "Doorzichtig",
                                            field: "vena_aantal_doorzichtig",
                                        },
                                        {
                                            colorValue: "lightBlue",
                                            color: "Lichtblauw",
                                            field: "vena_aantal_lichtblauw",
                                        },
                                        {
                                            colorValue: "red",
                                            color: "Rood",
                                            field: "vena_aantal_rood",
                                        },
                                        {
                                            colorValue: "yellow",
                                            color: "Geel",
                                            field: "vena_aantal_geel",
                                        },
                                        {
                                            colorValue: "green",
                                            color: "Groen",
                                            field: "vena_aantal_groen",
                                        },
                                        {
                                            colorValue: "blue",
                                            color: "Donkerblauw",
                                            field: "vena_aantal_donkerblauw",
                                        },
                                        {
                                            colorValue: "purple",
                                            color: "Paars",
                                            field: "vena_aantal_paars",
                                        },
                                        {
                                            colorValue: "#fff",
                                            color: "Wit",
                                            field: "vena_aantal_wit",
                                        },
                                        {
                                            colorValue: "grey",
                                            color: "Grijs",
                                            field: "vena_aantal_grijs",
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    )}

                    <>
                        <h3>Handeling</h3>
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <Form.Item label={t('route')} name="method" rules={[{ required: true, message: 'Toedieningsvorm is een verplicht veld' }]}>
                                    <Select disabled={props.editRecord !== undefined} size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Injectie"}>Injectie</Select.Option>
                                        <Select.Option value={"Instructie"}>Instructie</Select.Option>
                                        <Select.Option value={"Infuus"}>Infuus</Select.Option>
                                        <Select.Option value={"Venapunctie"}>Venapunctie</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item label={"Duur (min)"} name="duration">
                                    <InputNumber size={"large"} min={0} step={1} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>

                    <>
                        <h3>{t('dosage')}</h3>
                        <Row gutter={16}>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Item label={t('frequency')} name="frequency_times" rules={[{ required: true, pattern: '^[1-9]+[0-9]*$', message: 'Geef de freqentie in als getal' }]}>
                                    <Input size={"large"} addonAfter={t('timesPer')} disabled={bloedAfname || props.editRecord !== undefined} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Item label={t('period')} name="frequency_period" rules={[{ required: true, message: 'Periode is een verplicht veld' }]}>
                                    <Select size={"large"} placeholder={t('pickAnOption')} addonBefore={"per"} disabled={bloedAfname || props.editRecord !== undefined}>
                                        <Select.Option value={"Eenmalig"}>Eenmalig</Select.Option>
                                        <Select.Option value={"Dag"}>Dag</Select.Option>
                                        <Select.Option value={"Week"}>Week</Select.Option>
                                        <Select.Option value={"2-weken"}>2-weken</Select.Option>
                                        <Select.Option value={"3-weken"}>3-weken</Select.Option>
                                        <Select.Option value={"4-weken"}>4-weken</Select.Option>
                                        <Select.Option value={"5-weken"}>5-weken</Select.Option>
                                        <Select.Option value={"6-weken"}>6-weken</Select.Option>
                                        <Select.Option value={"8-weken"}>8-weken</Select.Option>
                                        <Select.Option value={"10-weken"}>10-weken</Select.Option>
                                        <Select.Option value={"12-weken"}>12-weken</Select.Option>
                                        <Select.Option value={"15-weken"}>15-weken</Select.Option>
                                        <Select.Option value={"Maand"}>Maand</Select.Option>
                                        <Select.Option value={"2-maanden"}>2-maanden</Select.Option>
                                        <Select.Option value={"3-maanden"}>3-maanden</Select.Option>
                                        <Select.Option value={"6-maanden"}>6-maanden</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!bloedAfname && (
                                <>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Form.Item label={`${t('amount')} ${hasExtraMedication ? " medicatie 1" : ""}`} name="frequency_amount" rules={[{ required: !bloedAfname, message: 'Hoeveelheid is een verplicht veld' }]}>
                                            <InputNumber disabled={props.editRecord !== undefined} style={{width: "100%"}} size={"large"} min={0} step={0.01} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Form.Item label={`${t('unit')} ${hasExtraMedication ? " medicatie 1" : ""}`} name="frequency_unit" rules={[{ required: true, message: 'Eenheid is een verplicht veld' }]}>
                                            <Select disabled={props.editRecord !== undefined} size={"large"}>
                                                <Select.Option value={"mcg"}>mcg</Select.Option>
                                                <Select.Option value={"mg"}>mg</Select.Option>
                                                <Select.Option value={"ml"}>ml</Select.Option>
                                                <Select.Option value={"gr"}>gr</Select.Option>
                                                <Select.Option value={"IE"}>IE</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}

                            {hasExtraMedication && (
                                <>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        &nbsp;
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        &nbsp;
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Form.Item label={`${t('amount')} ${hasExtraMedication ? " medicatie 2" : ""}`} name="frequency_amount_2" rules={[{ required: !bloedAfname, message: 'Hoeveelheid is een verplicht veld' }]}>
                                            <InputNumber disabled={props.editRecord !== undefined} style={{width: "100%"}} size={"large"} min={0} step={0.01} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6}>
                                        <Form.Item label={`${t('unit')} ${hasExtraMedication ? " medicatie 2" : ""}`} name="frequency_unit_2" rules={[{ required: true, message: 'Eenheid is een verplicht veld' }]}>
                                            <Select disabled={props.editRecord !== undefined} size={"large"}>
                                                <Select.Option value={"mcg"}>mcg</Select.Option>
                                                <Select.Option value={"mg"}>mg</Select.Option>
                                                <Select.Option value={"ml"}>ml</Select.Option>
                                                <Select.Option value={"gr"}>gr</Select.Option>
                                                <Select.Option value={"IE"}>IE</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </>

                    <>
                        <Row gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={6}>
                                <Form.Item label={"Aantal handelingen"} name="num_appointments" rules={[{ required: true, message: 'Aantal handelingen is een verplicht veld' }]}>
                                    <InputNumber style={{width: "100%"}} size={"large"} min={1} step={1} disabled={bloedAfname || props.editRecord !== undefined} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <Form.Item label={t('frequencyInfo')} name="frequency_info" rules={[{ required: false }]}>
                                    <TextArea rows={2} disabled={props.editRecord !== undefined} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {bloedAfname && (
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label={"Urinemonster afnemen ja/nee"} name="urine_monster" rules={[{ required: false }]}>
                                        <Checkbox disabled={props.editRecord !== undefined} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </>

                    <>
                        <h3>Voorraad</h3>
                        <Row gutter={16}>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Item label="Voorraad" name="stock_amount">
                                    <Input size={"large"} type="number" step={1} min={0} />
                                </Form.Item>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={6}>
                                <Form.Item label="Peildatum" name="stock_date">
                                    <Input size={"large"} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>

                    <>
                        <h3>{t('date')}</h3>
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <Form.Item label={t('urgent') + "?"} name="urgent" valuePropName="checked">
                                    <label>{t('urgentDesc')}</label>
                                    <br />
                                    <Switch disabled={true} checked={forcedUrgent} checkedChildren={<span>Spoed</span>} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={8} lg={6}>
                                <Form.Item label={t('deliveryMethod')} name="delivery_method" rules={[{ required: true, message: 'Leveringswijze is een verplicht veld' }]}>
                                    <Select disabled={props.editRecord !== undefined} size={"large"} onChange={(value) => setDeliveryMethod(value)} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"levering"}>{t('deliveredByHospital')}</Select.Option>
                                        <Select.Option value={"meegegeven"}>{t('patientPicksUpMedication')}</Select.Option>
                                        <Select.Option value={"extern"}>{t('externalPharmacy')}</Select.Option>
                                        <Select.Option value={"opdrachtgever"}>Opdrachtgever</Select.Option>
                                        <Select.Option value={"verpleegkundige"}>Via verpleegkundige</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!bloedAfname && (
                                <>
                                    <Col xs={24} sm={12} md={8} lg={4}>
                                        <Form.Item label={t('deliveryDate')} name="delivery_date" rules={[{ required: deliveryMethod === "levering", message: 'Leverdatum is een verplicht veld' }]}>
                                            <DatePicker locale={locale} disabled={deliveryMethod === "extern" || props.editRecord !== undefined} size={"large"} format={"DD-MM-YYYY"} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={4}>
                                        <Form.Item label={t('sendPrescriptionToPharmacy')} name="versturen_apotheek" rules={[{ required: deliveryMethod === "extern", message: 'Het is verplicht om aan te geven of het recept verstuurd moet worden.' }]} initialValue={"Niet versturen"}>
                                            <Select disabled={props.editRecord !== undefined} size={"large"} onChange={(value) => setDeliveryMethod(value)}>
                                                <Select.Option value={"Niet versturen"}>{"Niet versturen"}</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={6}>
                                {props.editRecord !== undefined && <>
                                    <span style={{color: "red"}}>Vul hier de nieuwe gewenste uitvoeringsdatum in. Als je de datum nog niet weet, vink dan aan "Onbekend". De verpleegkundige en de patiënt ontvangen een sms van de wijziging.</span>
                                </>}
                                <Form.Item
                                  name="appointment_date"
                                  label={pickedMedication && pickedMedication.toLowerCase().indexOf("thyrogen") > -1 ? "Targetdate eerste afspraak" : "Voorkeursdatum eerste toediening"}
                                  rules={[{ required: appointmentDateUnknown === false, message: 'Uitvoeringsdatum is een verplicht veld' }]}
                                  extra={<span>{pickedMedication && pickedMedication.toLowerCase().indexOf("thyrogen") > -1 && preferentProcedureDate && <span style={{ color: "red" }}>Tweede afspraak: {moment(preferentProcedureDate).add("1", "day").format("DD-MM-YYYY")}</span>}</span>}
                                >
                                    <DatePicker
                                      onChange={(d) => setPreferentProcedureDate(d)}
                                      locale={locale} disabled={appointmentDateUnknown || props.editRecord !== undefined}
                                      size={"large"}
                                      format={"DD-MM-YYYY"}
                                      disabledDate={(date) => {
                                        const isThyrogen = pickedMedication && pickedMedication.toLowerCase().indexOf("thyrogen") > -1;
                                        // Disable dates that are not monday, tuesday, thursday or friday
                                        if (isThyrogen) {
                                            return date.day() !== 1 && date.day() !== 4;
                                        }

                                        if (
                                          pickedMedication
                                          && pickedMedication.toLowerCase().indexOf("fulvestrant") > -1
                                          && companyId
                                          && [216,299,458,14499,14501].includes(companyId)
                                          && date.year() >= 2024
                                          && date.month() >= 3
                                        ) {
                                            return true;
                                        }
                                      }}
                                    />
                                </Form.Item>

                                {forcedUrgent && <div style={{ color: "red" }}>De aanvraag is gepland binnen 24 uur en wordt daarom behandeld als een spoedaanvraag.</div>}

                                <Form.Item label={"Datum uitvoering onbekend?"} name="appointment_date_unknown">
                                    <Switch disabled={props.editRecord !== undefined} onChange={checked => setAppointmentDateUnknown(checked)} />
                                </Form.Item>
                            </Col>
                            {!bloedAfname && (
                                <>
                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item label={t('prescriptionEnd')} name="end_date" rules={[{ required: false, message: '' }]}>
                                            <DatePicker disabled={props.editRecord !== undefined} locale={locale} size={"large"} format={"DD-MM-YYYY"} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={4}>
                                        <Form.Item label="Datum zelfzorg" name="selfcare_date" rules={[{ required: false, message: '' }]}>
                                            <DatePicker locale={locale} size={"large"} format={"DD-MM-YYYY"} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </>

                    {/* EXTRA VELDEN INFUUS */}
                    {infuus && <>
                        <h3>Infuus</h3>
                        <Row gutter={16}>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Vigori meting gewenst"} name="vigori_meting_gewenst">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Port-a-cath"} name="port_a_cath">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Noodmedicatie"} name="noodmedicatie">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6}>
                                <Form.Item label={"Noodmedicatie aanwezig"} name="noodmedicatie_aanwezig">
                                    <Select size={"large"} placeholder={t('pickAnOption')}>
                                        <Select.Option value={"Ja"}>Ja</Select.Option>
                                        <Select.Option value={"Nee"}>Nee</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}
                    {infuus && <Row gutter={16}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Soort noodmedicatie"} name="soort_noodmedicatie">
                                <Input size={"large"} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Dosering noodmedicatie"} name="dosering_noodmedicatie">
                                <Input size={"large"} />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {infuus && <Row gutter={16}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Epipen"} name="epipen">
                                <Select size={"large"} placeholder={t('pickAnOption')}>
                                    <Select.Option value={"Ja"}>Ja</Select.Option>
                                    <Select.Option value={"Nee"}>Nee</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item label={"Dosering epipen"} name="dosering_epipen">
                                <Input size={"large"} />
                            </Form.Item>
                        </Col>
                    </Row>}
                </div>

                <div style={{display: currentStep === 2 && !requestCompleted ? "block" : "none"}}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <h3>De arts of diens waarnemer:</h3>
                            <table style={{width: "100%", border: "1px solid #eee"}} cellPadding={10}>
                                <tbody>
                                    <tr><td>Naam voorschrijver</td><td>{formValues.prescriber}</td></tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col span={12}>
                            <h3>Verzoekt de zorginstelling:</h3>
                            <table style={{width: "100%", border: "1px solid #eee"}} cellPadding={10}>
                                <tbody>
                                <tr><td>Naam organisatie</td><td>Bleds B.V.</td></tr>
                                <tr><td>Vertegenwoordigt door </td><td>Mevrouw W.H. Kuipers</td></tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <h3>Onderstaande handeling (en) uit te voeren conform het daartoe door opgestelde protocol:</h3>
                    <Row gutter={5} style={{paddingTop: 20}}>
                        <table style={{width: 500, border: "1px solid #eee"}} cellPadding={10}>
                            <tbody>
                            <tr><td>Diagnose/indicatie</td><td>{formValues.diagnosis}</td></tr>
                            <tr><td>Medicatie</td><td>{formValues.medication}</td></tr>
                            <tr><td>Toedieningsvorm</td><td>{formValues.method}</td></tr>
                            <tr><td>Frequentie</td><td>{formValues.frequency_times}x per {formValues.frequency_period}, {formValues.frequency_amount} {formValues.frequency_unit}</td></tr>
                            <tr><td style={{verticalAlign: "text-top"}}>Extra info</td><td>{formValues.frequency_info}</td></tr>
                            </tbody>
                        </table>
                    </Row>
                </div>

                <div style={{display: currentStep === 2 && requestCompleted ? "block" : "none"}}>
                    <Result
                        status="success"
                        title="Klaar!"
                        subTitle={"Het uitvoeringsverzoek is opgeslagen met Patient ID " + newPatientId}
                        extra={[
                            <Button type="primary" key="console" onClick={props.onClose}>
                                Terug naar het overzicht
                            </Button>,
                        ]}
                    />
                </div>

            </Form>

            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }}
            >
                <Button onClick={props.onClose} style={{ marginRight: 8 }}>
                    {t('cancel')}
                </Button>

                {currentStep === 2 ? <Button loading={submitting} disabled={newPatientId !== 0} onClick={handleSubmit} style={{ marginRight: 8 }} type={"primary"} >
                    {t('submit')}
                </Button> : undefined}
            </div>
        </Drawer>
    );
};

export default CareRequestForm;
